import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import CustomProjectsImage from '../../icons/customProjectsImage.PNG';
import CustomProjectsIcon from '../../icons/customProjectsIcon.png';
import VRContentGenerationImage from '../../icons/vrContentGenerationImage.PNG';
import Insta360ProIcon from '../../icons/insta360Pro.png';

const LearnMore = ({url}) => {
  if(url){
      return (
          <Link
              href= {url}
              color="primary"
              variant="body2"
              fontWeight="bold"
              sx={{
              display: 'inline-flex',
              alignItems: 'center',
              '& > svg': { transition: '0.2s' },
              '&:hover > svg': { transform: 'translateX(2px)' },
              }}
              onClick={(event) => {
              event.stopPropagation();
              }}
          >
              <span>Learn more</span>
              <ChevronRightRoundedIcon
              fontSize="small"
              sx={{ mt: '1px', ml: '2px' }}
              />
          </Link>
      );
  } else{
      return (
          null
      );
  }
};

const items = [
  {
    icon: CustomProjectsIcon,
    title: 'Custom Application Development',
    titleFont: 'Audiowide',
    description:
      'We can design and develop custom VR and cloud projects specific to your organization',
    imageLight: `url(${CustomProjectsImage})`,
    imageDark: `url(${CustomProjectsImage})`,
    url:"#services"
  },
  {
    icon: Insta360ProIcon,
    title: '3D Content for Real Estate',
    titleFont: 'Audiowide',
    description:
      'We can generate 3D pictures and video of existing real estate using professional cameras. Combined with our Interia product, it offers a comprehensive real estate marketing solution.',
    imageLight: `url(${VRContentGenerationImage})`,
    imageDark: `url(${VRContentGenerationImage})`,
    url:"#services"
  },
  {
    icon: Insta360ProIcon,
    title: '3D Event Coverage',
    titleFont: 'Audiowide',
    description:
      'Whether it is your office events or a marriage in the family, we can generate 3D coverage of your event that others can experience as if they were personally there.',
    imageLight: `url(${VRContentGenerationImage})`,
    imageDark: `url(${VRContentGenerationImage})`,
    url:"#services"
  }
];

export default function Services() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
    window.location.href = items[selectedItemIndex].url;
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="services" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography 
              component="h2" variant="h4" color="text.primary"
              fontFamily={'Audiowide'} fontWeight={100}>
              Services
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              Some of the services we provide to help with your AR/VR projects.
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : '';
                    }
                    return selectedItemIndex === index ? 'primary.light' : '';
                  },
                  background: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : '';
                    }
                    return selectedItemIndex === index ? 'none' : '';
                  },
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description, titleFont, url }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                    selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.300';
                        }
                        return selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.700';
                      },
                    }}
                  >
                    <img
                      src={icon}
                      alt="logo of product"
                      width={'40px'}
                      height={'40px'}
                    />
                  </Box>
                  <div>
                    <Typography
                      color="primary.main"
                      variant="body2"
                      fontWeight="lighter"
                      fontSize={"20px"}
                      fontFamily={titleFont}
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    <LearnMore url={url}/>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
